import InformationCard from "@shared/InformationCard/InformationCard.vue"
import FocusedInformationCard from "@shared/FocusedInformationCard/FocusedInformationCard.vue"
import FilterChipsContainer from "@shared/FilterChipsContainer/FilterChipsContainer.vue"
import NoResults from "@shared/NoResults/NoResults.vue"
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import config from '@/helpers/config'
import { bookmarkArticle, unBookmarkArticle } from '@services/Articles.api'
import { getUserData } from '@services/User.api'
import { removeByAttr, getEntitiesList, removeUndefined } from '@mixins/helpers'
import InformationCardMixin from '@mixins/informationCardMixin' 
import searchMixin from '@mixins/searchMixin' 

export default {
    name:"Articles",
    mixins: [InformationCardMixin, searchMixin],

    components: {
        InformationCard,
        FocusedInformationCard,
        FilterChipsContainer,
        NoResults
    },

    data() {
        return {
            activeArticle: null,
            isActiveArticleBookmarked: false,
            bookmarkedArticles: [],
            
            articles: [],
            apiUrl: config.apiUrl,
            currentPage: 1,
            totalPages: 0,
            query: {
                pageSize: '',
                pageNumber: '',
                order: '',
                sortBy: ''
            },
            currentPageCountStart: 1,
            currentPageCountEnd: 1,
            filterChipsData: {},
            showFilterChipsContainer: false,
            hasNoResults: false,
            searchValue: "",
            isBookmarkLoading: false
        }
    },

    async created() {
        if (this.isLoggedIn) {
            await getUserData(this.user.id).then(
                response => {
                    this.bookmarkedArticles = response.data.payload.articles
                }, err => {
                    console.log('err ', err);
                }
            )
        }
    },

    mounted() {
        this.currentPage = parseInt(this.$route.query.pageNumber)
        this.searchValue = this.$route.query.value

        var query = {...this.getBaseQuery}
        delete query['reloadId']
        
        if (this.canSearchWithNoValue(query)) {
            var formattedQuery = this.getFormattedQuery(query)
            this.fetchArticles(formattedQuery)
            this.SET_SEARCH_VALUE(this.searchValue)

            this.filterChipsData = JSON.parse(JSON.stringify(this.getBaseQuery));
            this.setShowFilterChipsContainer()
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    },

    computed: {
        ...mapGetters('articles', ['getSearchResultArticles', 'getArticlesGlobalCount', 'getArticlesLocalCount' ]),
        ...mapGetters('search', ['getBaseQuery']),
        ...mapGetters('auth', ['isLoggedIn']),
        ...mapState('auth', ['user']),
        ...mapState('loaders', ['isPageLoading'])
    },

    methods: {
        ...mapActions('articles', ['fetchSearchResultArticles']),
        ...mapMutations('search', ['SET_SEARCH_VALUE']),
        ...mapMutations('loaders', ['SET_IS_PAGE_LOADING']),
        ...mapMutations('search', ['SET_BASE_QUERY', 'RESET_PAGE_NUMBER']),
        ...mapMutations('notification', ['notify']),

        async fetchArticles(query) {
            this.SET_IS_PAGE_LOADING(true)

            try {
                var data = {
                    userId: this.user?.id,
                    value: this.searchValue,
                    query: query
                }
    
                await this.fetchSearchResultArticles(data);
                var articlesArray = this.getSearchResultArticles
                this.setArticlesArray(articlesArray)
    
                this.setHasNoResults()
    
                this.totalPages = Math.ceil(this.getArticlesGlobalCount/this.getBaseQuery.pageSize)
                this.currentPageCountStart = parseInt(this.getBaseQuery.pageSize) * parseInt(this.getBaseQuery.pageNumber) - parseInt(this.getBaseQuery.pageSize) + 1
                this.currentPageCountEnd = parseInt(this.getBaseQuery.pageSize) * parseInt(this.getBaseQuery.pageNumber) - parseInt(this.getBaseQuery.pageSize) + parseInt(this.getArticlesLocalCount)
    
                if (this.$route.hash) {
                    this.activeArticle = this.articles.find(article => article.id == this.$route.hash.substring(1)) 
                }
                this.SET_IS_PAGE_LOADING(false)    
            } catch(err) {
                this.SET_IS_PAGE_LOADING(false)
                this.setHasNoResults()
                this.showErrorToast();
            }
        },

        setReloadId() {
            var query = JSON.parse(JSON.stringify(this.getBaseQuery));
            let reloadId = Date.now().toString(36) + Math.random().toString(36).substring(2);
            query["reloadId"] = reloadId

            this.SET_BASE_QUERY(query)
        },

        getFormattedQuery(query) {
            var formattedQuery = "?"

            for (const key in query) {
                if (Object.hasOwnProperty.call(query, key)) {
                    if (query[key]) {
                        if (Array.isArray(query[key])) {
                            for (let i = 0; i < query[key].length; i++) {
                                formattedQuery += key + "=" + query[key][i] + '&'
                            }
                        } else {
                            formattedQuery += key + "=" + query[key] + '&'
                        }
                    }
                }
            }

            formattedQuery = formattedQuery.substring(0, formattedQuery.length - 1);
            return formattedQuery
        },

        setArticlesArray(articlesArray) {
            this.articles = articlesArray.map(article => {
                return {
                    id: article.id,
                    isSafirContent: article.sourceName === "السفير",
                    src: this.apiUrl + '/entities/images/raw/' + (article.newPdfPath && article.newPdfPath.length > 0 ? article.newPdfPath : article.oldPdfPath) + '/400',
                    lazysrc: this.apiUrl + '/entities/images/raw/' + (article.newPdfPath && article.newPdfPath.length > 0 ? article.newPdfPath : article.oldPdfPath) + '/100',
                    title: article.title,
                    authorTitle: "إسم الكاتب",
                    authorName: removeUndefined(article.authorName),
                    sourceName: removeUndefined(article.sourceName),
                    date: article.year + " - " + article.month + " - " + article.day,
                    entities: getEntitiesList(article.entities),
                    description: article.content,
                    direction: article.languageName === 'EN' | 'FR' ? 'ltr' : 'rtl',
                    newPdfPath: article.newPdfPath,
                    oldPdfPath: article.oldPdfPath,
                }
            })
        },

        showErrorToast() {
            this.notify({
                message: "هناك خطأ ما. يرجى المحاولة مرة أخرى",
                show: true,
              });
        },

        setHasNoResults() {
            if (this.articles.length == 0) {
                this.hasNoResults = true
            } else {
                this.hasNoResults = false
            }
        },

        setShowFilterChipsContainer() {
            delete this.filterChipsData['order']; 
            delete this.filterChipsData['pageNumber']; 
            delete this.filterChipsData['pageSize']; 
            delete this.filterChipsData['sortBy']; 

            if (this.filterChipsData) {
                if (Object.keys(this.filterChipsData).length === 0 && this.filterChipsData.constructor === Object) {
                    this.showFilterChipsContainer = false
                } else {
                    this.showFilterChipsContainer = true
                }
            } else {
                this.showFilterChipsContainer = false
            }
        },

        setActiveArticle(article) {
            this.activeArticle = article

            if (this.isArticleBookmarked(article.id)) {
                this.isActiveArticleBookmarked = true
            } else {
                this.isActiveArticleBookmarked = false
            }

            this.$router.push({
                pathname: '/articles',
                query: {
                    ...this.$route.query
                },
                hash: '#' + article.id
            }, undefined, { shallow: true})

            window.scrollTo(0,0);
        },

        isArticleBookmarked(id) {
            return this.bookmarkedArticles.filter(article => article.id === id).length > 0
        },

        hideFocusedArticle() {
            this.$router.push({
                pathname: '/articles',
                query: {
                    ...this.$route.query
                },
                hash: ''
            }, undefined, { shallow: true})

            this.activeArticle = null
        },

        goToPageRouter(pageName) {
            this.$router.push({
                name: pageName,
                // params: {
                //     value: this.searchValue
                // },
                query: this.getBaseQuery
            })
        },

        goToPage(pageName) {
            // if (this.searchValue) {
                this.goToPageRouter(pageName)
            // } else {
            //     this.$router.push({
            //         name: 'home'
            //     })
            // }
        },

        handlePageChange(value) {
            this.currentPage = value
            var query = JSON.parse(JSON.stringify(this.getBaseQuery));
            query['pageNumber'] = value
            this.SET_BASE_QUERY(query)
            var currentPage = this.$route.name
            this.goToPage(currentPage)
            this.$router.go()
            window.scrollTo(0,0);
        },

        deleteFilterChip(type, filter) {
            this.setReloadId()

            var query = JSON.parse(JSON.stringify(this.getBaseQuery));

            if (typeof query[type] === 'string' || query[type] instanceof String) {
                query[type] = ""
            } else if (Array.isArray(query[type])) {
                if (query[type].length == 1) {
                    delete query[type];
                }
                else {
                    const index = query[type].indexOf(filter);
                    if (index > -1) {
                        query[type].splice(index, 1);
                    }
                }
            }

            this.SET_BASE_QUERY(query)
            this.RESET_PAGE_NUMBER()

            var currentPage = this.$route.name
            this.goToPage(currentPage)
            this.$router.go()
            window.scrollTo(0,0);
        },

        async toggleBookmarkArticle(id) {
            if (this.isLoggedIn) {
                var data = {
                    userId: this.user.id, 
                    articleId: id
                }

                this.isBookmarkLoading = true
                if (this.isArticleBookmarked(id)) {
                    await this.unBookmarkArticle(data)
                } else {
                    await this.bookmarkArticle(data)
                }
                this.isBookmarkLoading = false
            } else {
                this.notify({message: 'الرجاء تسجيل الدخول', show: true})
            }
        },

        async bookmarkArticle(data) {
            await bookmarkArticle(data).then(
                response => {
                    this.bookmarkedArticles.push({id: response.data.payload.articleId})
                    this.isActiveArticleBookmarked = true
                }, () => {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            )
        },

        async unBookmarkArticle(data) {
            await unBookmarkArticle(data).then(
                () => {
                    removeByAttr(this.bookmarkedArticles, 'id', data.articleId)
                    this.isActiveArticleBookmarked = false
                }, () => {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            )
        },

        async downloadArticle() {
            if (this.isLoggedIn) {
                var pdfPath = this.activeArticle.newPdfPath ? this.activeArticle.newPdfPath : this.activeArticle.oldPdfPath
                
                if (pdfPath) {
                    let url = config.apiUrl + '/entities/articles/raw/' + pdfPath
                    window.open(url)
                } else {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            } else {
                this.notify({message: 'الرجاء تسجيل الدخول', show: true})
            }
        },

        async downloadArticleAsZip() {
            if (this.isLoggedIn) {
                var pdfPath = this.activeArticle.newPdfPath ? this.activeArticle.newPdfPath : this.activeArticle.oldPdfPath
                
                if (pdfPath) {
                    let url = config.apiUrl + '/entities/articles/raw-zip/' + pdfPath
                    window.open(url)
                } else {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
            } else {
                this.notify({message: 'الرجاء تسجيل الدخول', show: true})
            }
        }
    }
}